/* App.css */

body {
  background-color: #000;
  color: #0f0;
  font-family: 'Courier New', Courier, monospace;
}

.app-container {
  margin-top: 4rem;
}

.app-form {
  margin-bottom: 2rem;
}

.app-form-group {
  margin-bottom: 1.5rem;
}

.app-input {
  width: 100%;
  background-color: #000;
  color: #0f0;
  border: 1px solid #0f0;
  padding: 0.5rem;
  font-family: 'Courier New', Courier, monospace;
}

.app-input::placeholder {
  color: #555;
}

.app-button {
  margin-top: 1.5rem;
  background-color: #00f;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
}

.app-alert {
  margin-top: 1.5rem;
}

.app-image-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.app-image-wrapper {
  position: relative;
  overflow: hidden;
  border: 1px solid #0f0;
  box-shadow: 0 0 10px rgba(0, 240, 0, 0.3);
}

.app-image {
  transform-origin: top left;
  transform: none !important; /* Reset inline styles */
  width: 100%;
  height: auto;
}

.app-top-text,
.app-bottom-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(0, 240, 0, 0.5);
}

.app-top-text {
  top: 5px;
  left: 0;
  color: #0f0;
}

.app-bottom-text {
  bottom: 5px;
  left: 0;
  color: #0f0;
}

/* Mock Images */
.app-image {
  content: url('https://via.placeholder.com/800x600'); /* Replace with your image URL */
}
