/* Contact.css */

body {
  background-color: #000;
  color: #0f0;
  font-family: 'Courier New', Courier, monospace;
}

.contact-container {
  margin-top: 4rem;
}

.contact-form {
  background-color: #000;
  color: #0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 240, 0, 0.3);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  background-color: black;
  color: #0f0;
  border: 1px solid #0f0;
  padding: 0.5rem;
  font-family: 'Courier New', Courier, monospace;
}

.form-control::placeholder {
  color: #555;
}

.btn-primary {
  margin-top: 1.5rem;
  background-color: #00f;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
}
